@import (reference) "../_variables";
@import (reference) "../../mixins/_styleResets";

.hero {
	h1 {
		font-size: 38px;
		line-height: @line-height-pct;
	}

	text-align: left;
	background-repeat: no-repeat;

	@media (max-width: @screen-mobile-max) {
		@background-ratio: round((5 / 6 * 100));

		background-image: url("/resources/img/79/stripes-sm.jpg");

		section.content > *:last-child {
			margin-bottom: ~"@{background-ratio}vw";
		}

		background-position: bottom center;
		background-size: contain;
	}

	@media (min-width: @screen-tablet) {
		background-image: url("/resources/img/79/stripes.jpg");

		background-size: 617px;
		background-position: left ~"calc(max(375px, ((100vw - @{max-width-hero}) / 2) + 435px))" center;
		min-height: ~"calc(min(33vw, 515px))";
		max-height: 658px;

		section.content > * {
			max-width: 315px;
		}
	}

	.action-dropdown {
		min-width: 315px;
	}
}

main {
	padding: 0;
}

#top-features {
	padding-top: @spacing-paragraph;
}

ul.top-feature-buttons {
	.plain-ul();
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (min-width: @screen-tablet) {
		flex-direction: row;
	}

	li {
		a.button {
			padding: @spacing-paragraph @spacing-paragraph;

			@media (max-width: @screen-mobile-max) {
				width: 315px;
			}
		}

		margin-bottom: @spacing-paragraph;

		&:not(:last-child) {
			@media (min-width: @screen-tablet) {
				margin-right: 15px;
			}
		}
	}
}

#big-features ul {
	.plain-ul();
	display: grid;
	background-color: @color-steel;
	grid: auto-flow / auto;
	grid-gap: 1px;

	@media (min-width: @screen-tablet) {
		grid: auto / auto-flow;
	}

	li {
		display: grid;
		padding: @spacing-standard 0;
		background-color: @color-white;

		@media (min-width: @screen-tablet) {
			padding: 0 @spacing-standard;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

		}

		p {
			text-align: left;
		}

		button, a.button {
			align-self: end;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			padding: @spacing-paragraph @spacing-paragraph;
		}
	}
}

h4.section-title {
	font-weight: @font-weight-header-bold;
	font-size: 26px;
	line-height: @line-height-pct;
}

#highlight-testimonial {
	border: 1px solid @color-gray;
	border-radius: 4px;
	background-image: url("/resources/img/79/desktop-bw-sm.jpg");
	background-size: cover;
	background-position: center center;
	padding: @spacing-standard;

	@media (min-width: @screen-tablet) {
		background-image: url("/resources/img/79/desktop-bw.jpg");
	}

	display: flex;
	flex-direction: column;

	.acronym-explained {
		font-size: 24px;
		line-height: @line-height-pct;
		color: @color-blue-primary;
		font-weight: 500;
		text-align: center;
	}

	.the-testimonial {
		margin-top: @spacing-paragraph;
		font-family: @font-family-header;
		color: @color-blue-2 !important;
	}

	@media (min-width: @screen-tablet) {
		flex-direction: row;

		.acronym-explained {
			text-align: left;
			flex: 1;
		}

		.the-testimonial {
			flex: 2;
			margin-top: 0;
			margin-left: @spacing-standard;
		}
	}
}

#testimonials {
	ul {
		.plain-ul();

		@media (min-width: @screen-tablet) {
			column-count: 2;
		}

		li {
			break-inside: avoid;
			&:not(:last-child) {
				margin-bottom: 45px;
			}
		}
	}
}