@import (reference) "../global/components/expertContributions.less";
@import (reference) "../global/components/top-breadcrumbs.less";
@import (reference) "../mixins/_styleResets.less";
@import (reference) "_variables.less";
@import "../global/components/_dial-gauge.less";
@import "../global/components/_plainExam.less";
@import "../global/components/_practice-questions-module.less";


.homepage {
	@import "pages/_home.less";
}

.suite {
	header.hero {
		text-align: left;

		.action-dropdown {
			min-width: 315px;
		}

		@media (min-width: @screen-tablet) {
			background-repeat: no-repeat;
			background-image: url("/resources/img/79/test-suite-cropped.jpg");
			background-position: right ~"calc(max(0px, (100vw - @{max-width-hero}) / 2))" center;
			background-size: contain;

			section.content > * {
				max-width: 375px;
			}
		}
	}
}

.individual-test {
	.passage {
		background-color: @color-gray-e;
		border-radius: 4px;
	}
	
	.practice-exam__question {
		font-family: 'Lato', sans-serif !important;
		font-weight: 400 !important;
	}

	main {
		padding-top: 60px;
		padding-bottom: 0;
	}

	.body-btn + h2, .body-btn + h3, .body-btn + h4 {
		margin-top: 60px;
	}

	.quote-block {
		margin-top: 60px;
	}
}

.info {
}

.index {
	.make-breadcrumbs(@background-color: #DDD);

	nav.breadcrumbs {
		a {
			color: @color-link !important;
		}
	}

	header.hero {
		background-color: #DDD;
		
		h1 {
			color: @color-blue-primary;
		}
	}
}

.expert-contributions-container {
	.expert-contributions(@color-link, @color-gray-d, @color-link);
	
	.view-more-state ~ .view-more-trigger:after {
		color: @color-link !important;
		background-color: revert !important;
		border-radius: 50%;
	}
}

.exam-container--practice-module {
	margin: 60px 0;
}

.error--body {
	text-align: center;
	padding: 30vh 0;
	
	@media (min-width: @screen-laptop) {
		padding: 36vh 0;
	}
}
main{
	padding-bottom: 0;
	section.content:last-of-type {
		padding-bottom: 45px;
	}
}
