@import "../global/global-variables";

@color-blue-link: #2A63AF;
@color-blue-primary: #002868;
@color-blue-2: #263A5F;
@color-blue-3: #207DE9;
@color-blue-light: #BDEBF6;
@color-blue-5: #BDEBF6;
@color-blue-6: #DAF7FE;
@color-blue-gray: #CFEAF2;

@color-red: #BF0A30;
@color-red-2: #B7092E;

@color-gray-f8: #F8F8F8;

@color-gray-e: #EEE;
@color-gray-d: #DDD;
@color-gray-8: #888;
@color-gray-7: #777;
@color-gray-5: #555555;
@color-gray-4: #444;
@color-gray-3: #333;
@color-gray-2: #E1E1E1;
@color-gray: #A0A0A0;
@color-gray-red: #F2F1F1;
@color-steel: #778899;
@color-gray-border: #D9D9D9;
@color-gray-select: #B6B6B6;
@color-button-border: #035EA5;
@button-gradient: linear-gradient(180deg, #035EA5 0%, #1A3B66 100%);

@color-brand: @color-blue-primary;
@color-brand-light: @color-blue-3;
@color-text: @color-gray-3;
@color-link: @color-blue-link;
@color-highlight: @color-red;
@color-button: @color-blue-3;
@color-button-active: @color-button-border;
@color-button-active-dark: #144476;
@color-practice-exam-success: #62A730;
@color-bleed-background: #E0E2E5;
@color-white: #FFFFFF;

@screen-xs: 375px;
@screen-xs-max: 575px;
@screen-mobile: 576px;
@screen-mobile-max: (768px - 1);
@screen-tablet: 768px;
@screen-tablet-max: @screen-laptop - 1;
@screen-laptop: 992px;
@screen-max-content: (@max-width-content + @spacing-standard * 2);
@screen-max-hero: (@max-width-hero + @spacing-standard * 2);
@screen-xl: 1200px;
@screen-desktop: @screen-xl;
@screen-xxl: 1400px;

@spacing-large: 45px;
@spacing-standard: 30px;
@spacing-paragraph: 15px;
@spacing-minimum: 10px;

@font-import: 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,600;0,700;1,400&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap';

@font-family: 'Lora', sans-serif;
@font-family-header: 'Lato', sans-serif;

@font-weight-header-bold: 700;
@font-weight-bold: 600;
@font-weight: 400;
@font-size-tiny: 13px;

@font-size: 16px;
@font-size-lg: 18px;
@font-size-sm: 14px;
@font-size-xs: 13px;
@font-size-xl: 20px;
@font-size-cta: 24px;
@font-size-larger-not-huge: 30px;

@max-width-content: 1000px;
@max-width-hero: 1200px;

@letter-spacing-ssp: 0.1px;
@line-height-factor: 1.4;
@line-height-pct: @line-height-factor * 100%;

@border-radius-large: 6px;
.font-body() {
	font-family: @font-family;
	font-weight: @font-weight;
	font-size: @font-size;
	line-height: @line-height-pct;
	color: @color-text;
}

.font-hero() {
	.font-body();
	font-size: 20px;
	color: white;
}

.font-h3() {
	.font-body();
	color: @color-brand;
	font-family: @font-family;

	font-size: 20px;

	@media (min-width: @screen-tablet) {
		font-size: 28px;
	}
}

.font-h2() {
	.font-body();
	font-family: @font-family-header;

	color: @color-brand;
	font-size: 22px;

	@media (min-width: @screen-tablet) {
		font-size: 34px;
	}

	font-weight: @font-weight-header-bold;
}

.font-h1() {
	.font-h2();

	color: @color-brand;
	font-size: 26px;

	@media (min-width: @screen-tablet) {
		font-size: 38px;
	}

	font-weight: @font-weight-header-bold;
}

.hero-bg() {
	background-color: @color-blue-2;
}

.font-link() {
	color: @color-link;
	font-family: @font-family-header;
	font-weight: @font-weight-header-bold;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	p & {
		font-family: @font-family;
		font-weight: @font-weight-bold;
	}
}

@material-expand-more: '\e5cf';
@material-expand-less: '\e5ce';
@material-arrow-right: '\e5df';
@material-arrow-forward: '\e5c8';

@color-error: #A94442;
@color-blue-3: #6A98B1;
@color-gray-1: #F8F8F8;
@color-gray-4: #777777;
@font-weight-semibold: 500;